exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contentful-language-slug-autos-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/autos/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-autos-tsx" */),
  "component---src-pages-contentful-language-slug-aviation-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/aviation/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-aviation-tsx" */),
  "component---src-pages-contentful-language-slug-banks-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/banks/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-banks-tsx" */),
  "component---src-pages-contentful-language-slug-corporate-finance-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/corporate-finance/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-corporate-finance-tsx" */),
  "component---src-pages-contentful-language-slug-economics-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/economics/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-economics-tsx" */),
  "component---src-pages-contentful-language-slug-energy-natural-resources-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/energy-natural-resources/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-energy-natural-resources-tsx" */),
  "component---src-pages-contentful-language-slug-fund-asset-managers-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/fund-asset-managers/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-fund-asset-managers-tsx" */),
  "component---src-pages-contentful-language-slug-gaming-lodging-leisure-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/gaming-lodging-leisure/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-gaming-lodging-leisure-tsx" */),
  "component---src-pages-contentful-language-slug-global-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/global/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-global-tsx" */),
  "component---src-pages-contentful-language-slug-healthcare-pharma-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/healthcare-pharma/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-healthcare-pharma-tsx" */),
  "component---src-pages-contentful-language-slug-industrials-transportation-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/industrials-transportation/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-industrials-transportation-tsx" */),
  "component---src-pages-contentful-language-slug-infrastructure-project-finance-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/infrastructure-project-finance/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-infrastructure-project-finance-tsx" */),
  "component---src-pages-contentful-language-slug-insights-commentaries-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/insights/commentaries.tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-insights-commentaries-tsx" */),
  "component---src-pages-contentful-language-slug-insights-esg-ratings-action-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/insights/esg-ratings-action.tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-insights-esg-ratings-action-tsx" */),
  "component---src-pages-contentful-language-slug-insights-sustainable-insights-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/insights/sustainable-insights.tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-insights-sustainable-insights-tsx" */),
  "component---src-pages-contentful-language-slug-insurance-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/insurance/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-insurance-tsx" */),
  "component---src-pages-contentful-language-slug-international-public-finance-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/international-public-finance/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-international-public-finance-tsx" */),
  "component---src-pages-contentful-language-slug-islamic-finance-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/islamic-finance/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-islamic-finance-tsx" */),
  "component---src-pages-contentful-language-slug-metals-mining-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/metals-mining/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-metals-mining-tsx" */),
  "component---src-pages-contentful-language-slug-non-bank-financial-institutions-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/non-bank-financial-institutions/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-non-bank-financial-institutions-tsx" */),
  "component---src-pages-contentful-language-slug-real-estate-homebuilding-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/real-estate-homebuilding/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-real-estate-homebuilding-tsx" */),
  "component---src-pages-contentful-language-slug-regulation-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/regulation/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-regulation-tsx" */),
  "component---src-pages-contentful-language-slug-retail-consumer-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/retail-consumer/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-retail-consumer-tsx" */),
  "component---src-pages-contentful-language-slug-sovereigns-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/sovereigns/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-sovereigns-tsx" */),
  "component---src-pages-contentful-language-slug-structured-finance-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/structured-finance/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-structured-finance-tsx" */),
  "component---src-pages-contentful-language-slug-technology-media-telecom-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/technology-media-telecom/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-technology-media-telecom-tsx" */),
  "component---src-pages-contentful-language-slug-uncategorized-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/uncategorized/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-uncategorized-tsx" */),
  "component---src-pages-contentful-language-slug-us-public-finance-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/us-public-finance/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-us-public-finance-tsx" */),
  "component---src-pages-contentful-language-slug-utilities-power-tsx": () => import("./../../../src/pages/{ContentfulLanguage.slug}/utilities-power/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-language-slug-utilities-power-tsx" */),
  "component---src-pages-contentful-market-sector-slug-tsx": () => import("./../../../src/pages/{ContentfulMarketSector.slug}/[...].tsx" /* webpackChunkName: "component---src-pages-contentful-market-sector-slug-tsx" */),
  "component---src-pages-dynamic-not-found-tsx": () => import("./../../../src/pages/dynamicNotFound.tsx" /* webpackChunkName: "component---src-pages-dynamic-not-found-tsx" */),
  "component---src-pages-esg-ratings-action-tsx": () => import("./../../../src/pages/esg-ratings-action.tsx" /* webpackChunkName: "component---src-pages-esg-ratings-action-tsx" */),
  "component---src-pages-global-tsx": () => import("./../../../src/pages/global/[...].tsx" /* webpackChunkName: "component---src-pages-global-tsx" */),
  "component---src-pages-insights-commentaries-tsx": () => import("./../../../src/pages/insights/commentaries.tsx" /* webpackChunkName: "component---src-pages-insights-commentaries-tsx" */),
  "component---src-pages-insights-esg-ratings-action-tsx": () => import("./../../../src/pages/insights/esg-ratings-action.tsx" /* webpackChunkName: "component---src-pages-insights-esg-ratings-action-tsx" */),
  "component---src-pages-insights-sustainable-insights-tsx": () => import("./../../../src/pages/insights/sustainable-insights.tsx" /* webpackChunkName: "component---src-pages-insights-sustainable-insights-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-site-tsx": () => import("./../../../src/pages/site.tsx" /* webpackChunkName: "component---src-pages-site-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/Landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-layouts-tsx": () => import("./../../../src/templates/Layouts.tsx" /* webpackChunkName: "component---src-templates-layouts-tsx" */),
  "component---src-templates-market-sector-tsx": () => import("./../../../src/templates/MarketSector.tsx" /* webpackChunkName: "component---src-templates-market-sector-tsx" */)
}

